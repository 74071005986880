import React from "react";
import styled from "styled-components";
import { Icon } from "../../common";
import {
  AccountPanelArticle,
  AccountPanelSection,
  AccountPanelSectionWrapper,
  AccountPanelSectionCurvedBottom,
} from "../AccountPanelLayout";
import PhoneIcon from "../../../images/PMB_phone_icon.png";
import LetterIcon from "../../../images/PMB_email_icon.png";

const FaqPanelList = styled.ul`
  list-style: none;
  padding-left: 90px;
  max-width: 980px;
`;

const FaqPanelListItem = styled.li`
  margin-bottom: 24px;
  h3 {
    margin-bottom: 16px;
  }
`;

const FaqPanelWrapper = styled(AccountPanelSectionWrapper)`
  h2 {
    color: black;
    margin-bottom: 40px;
  }
`;
const FaqPanelArticle = styled(AccountPanelArticle)`
  padding: ${(props) => props.padding || "0"};
  h2 {
    color: black;
  }
`;

const FaqPanelContacts = styled.div`
  display: flex;
  justify-content: center;
`;

const FaqPanelContactsLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: black;
  font-size: 20px;
  font-weight: 700;

  &:first-child {
    padding-right: 70px;
  }

  &:last-child {
    padding-left: 70px;
  }

  ${Icon} {
    position: unset;
  }

  span {
    padding-left: 20px;
  }
`;

export const AccountFaqPanel = ({ accountFaq, accountContact }) => {
  return (
    <>
      <h2>FAQ och kontakt</h2>
      <FaqPanelWrapper>
        <AccountPanelSection bgColor="var(--pmbJungleMist)">
          <AccountPanelSectionCurvedBottom>
            <FaqPanelArticle padding="50px 0">
              <h2>FAQ</h2>
              <FaqPanelList>
                {!!accountFaq &&
                  accountFaq.map(
                    ({ accountFaqQuestion, accountFaqAnswer }, i) => (
                      <FaqPanelListItem key={i}>
                        <h3>{accountFaqQuestion}</h3>
                        <p>{accountFaqAnswer}</p>
                      </FaqPanelListItem>
                    )
                  )}
              </FaqPanelList>
            </FaqPanelArticle>
          </AccountPanelSectionCurvedBottom>
        </AccountPanelSection>

        <AccountPanelSection>
          <AccountPanelSectionCurvedBottom odd>
            <FaqPanelArticle>
              <h2>Kontakt</h2>
              <FaqPanelContacts>
                <FaqPanelContactsLink
                  href={`tel:${accountContact.accountContactPhone}`}
                >
                  <Icon icon={PhoneIcon} />
                  <span>{accountContact.accountContactPhone}</span>
                </FaqPanelContactsLink>
                <FaqPanelContactsLink
                  href={`mailto:${accountContact.accountContactEmail}`}
                >
                  <Icon icon={LetterIcon} />
                  <span>{accountContact.accountContactEmail}</span>
                </FaqPanelContactsLink>
              </FaqPanelContacts>
            </FaqPanelArticle>
          </AccountPanelSectionCurvedBottom>
        </AccountPanelSection>
      </FaqPanelWrapper>
    </>
  );
};
