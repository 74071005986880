import React, { useState } from "react";
import { DeleteButton } from "../../../common";
import { AccountButton } from "../../AccountButton";
import { DeleteUserBox, MyUsersTableRow } from "./MyUsersPanelComponents";

const UserTableRow = ({
  userName,
  password,
  isTeacher,
  deleteUser,
  index,
  progress,
  openModalHandler,
  gameLock
}) => {
  const [displayDeletePrompt, setDisplayDeletePrompt] = useState(false);
  return (
    <MyUsersTableRow borderHeight="36px" panelHeight="94px">
      <td>{userName}</td>
      <td>
        {!displayDeletePrompt && (
          <AccountButton
            type="button"
            value={userName}
            onClick={() => openModalHandler(index)}
          >
            Progress
          </AccountButton>
        )}
      </td>
      <td>{!displayDeletePrompt && <span>{password}</span>} </td>
      {/* <td style={{textAlign: 'left'}}>{gameLock}</td> */}
      <td>{isTeacher && <span>Lärare</span>}</td>
      <td>
        <DeleteButton onClick={() => setDisplayDeletePrompt(true)}>
          <span className="sr-only">Radera användare</span>
        </DeleteButton>

        <DeleteUserBox show={displayDeletePrompt}>
          <p>Är du säker på att du vill radera användaren?</p>
          <AccountButton
            type="button"
            onClick={() => {
              deleteUser(index);
              setDisplayDeletePrompt(false);
            }}
          >
            Ja
          </AccountButton>
          <AccountButton
            type="button"
            onClick={() => setDisplayDeletePrompt(false)}
          >
            Avbryt
          </AccountButton>
        </DeleteUserBox>
      </td>
    </MyUsersTableRow>
  );
};

export default UserTableRow;
