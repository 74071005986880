import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import {
  ModalBackdrop,
  ModalBox,
  ModalBoxContent,
  ModalWrapper,
} from "./ModalComponents";
import ReactDOM from "react-dom";
import { IconButton } from "../IconButton";
import CloseX from "../../../images/close_x.png";

const Modal = forwardRef(({ children }, ref) => {
  const [showModal, setShowModal] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      openModal: () => openModalHandler(),
      closeModal: () => closeModalHandler(),
    };
  });

  const openModalHandler = () => {
    setShowModal(true);
  };
  const closeModalHandler = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [showModal]);

  if (showModal)
    return ReactDOM.createPortal(
      <ModalWrapper>
        <ModalBackdrop onClick={closeModalHandler} />
        <ModalBox>
          <IconButton
            icon={CloseX}
            top="20px"
            right="20px"
            onClick={closeModalHandler}
          />
          <ModalBoxContent>{children}</ModalBoxContent>
        </ModalBox>
      </ModalWrapper>,
      document.getElementById("portal")
    );

  return null;
});

export default Modal;
