import React, { useState, useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import MapLong from "../../../images/PMB_account_banner.png";
import TopImageTextBg from "../../../images/PMB_Teaching_TopTextBg.svg";
import { CounterIcon, Icon } from "../../common";
import {
  AccountPanelSection,
  AccountPanelSectionWrapper,
  AccountPanelArticle,
  AccountPanelSectionCurvedBottom,
} from "../AccountPanelLayout";
import AccountTogglePanel from "../AccountTogglePanel";
import DownloadIcon from "../../../images/PMB_download_icon.png";

const TeachingContentWrapper = styled(AccountPanelSectionWrapper)`
  counter-reset: TeachingSection; // for counter

  &.scrolled {
    padding-top: 300px; // TopImageWrapper height
  }
`;

const TeachingHeader = styled.header`
  background-color: var(--pmbMystic);
  position: relative;
  padding: 90px 0 60px;
  width: calc(100% + 300px);
  left: -150px;
`;

const TeachingArticleHeader = styled.header`
  display: grid;
  grid-template-columns: 84px auto;
  margin-bottom: 55px;
  margin-left: 20px;
`;

const TeachingArticleHeaderText = styled.div`
  grid-column: 2 / 3;
  padding-left: 55px;

  h3 {
    margin-bottom: 12px;
  }
`;

// triggers sticky TeachingTopImage with IntersectionObserver
const IntersectionSentinel = styled.div`
  height: 1px;
`;

const TeachingTopImageWrapper = styled.div`
  position: relative;
  width: calc(100% + 80px);
  left: -55px;
  height: 300px;
  background-color: var(--accountBg);
  z-index: 6;

  &.scrolled {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
`;

const TeachingTopImage = styled.div`
  background-image: url(${MapLong});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  background-position: right;
  position: relative;
`;

const TeachingTopImageTextBackground = styled.div`
  background-image: url(${TopImageTextBg});
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  width: 650px;
  height: 200px;
  left: 100px;
  top: 80px;
  z-index: 5;

  .scrolled & {
    top: 80px;
    left: 70px;
  }

  p {
    top: 5px;
    left: 35px;
    height: 155px;
    overflow: hidden;
    padding: 30px;
    position: absolute;
  }
`;

const TeachingExpandableInfoContainer = styled.div``;

const TeachingDownloadLink = styled.a`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  color: black;
  font-size: 20px;

  ${Icon} {
    position: unset;
    padding-right: 40px;
  }

  span {
    padding-right: 20px;
  }
`;

export const TeachingMaterialsPanel = ({
  teachingIntro,
  // teachingListing,
  teachingTopImageText,
  myUsersShortcut,
}) => {
  const [scrolled, setScrolled] = useState(false);
  const intersectionSentinel = useRef();

  const topImageIntersectionOptions = {
    threshold: 1,
    rootMargin: "107px 0px 0px 0px", // height of h1 as margin-top
  };

  const topImageObserver = new IntersectionObserver((entries) => {
    setScrolled(!entries[0].isIntersecting);
  }, topImageIntersectionOptions);

  useLayoutEffect(() => {
    let sentinel = intersectionSentinel.current;
    topImageObserver.observe(sentinel);
    return () => topImageObserver.unobserve(sentinel);
  }, [topImageObserver]);

  return (
    <>
      <IntersectionSentinel ref={intersectionSentinel} />
      <h2>Handledning</h2>
      <TeachingTopImageWrapper className={scrolled && "scrolled"}>
        <TeachingTopImageTextBackground>
          <p>{teachingTopImageText}</p>
        </TeachingTopImageTextBackground>
        <TeachingTopImage />
      </TeachingTopImageWrapper>
      <TeachingContentWrapper className={scrolled && "scrolled"}>
        <TeachingHeader>
          {teachingIntro.map(
            (
              {
                teachingIntroHeading,
                teachingIntroPreamble,
                teachingIntroList,
                teachingIntroPdf,
                teachingIntroPdfShow,
              },
              i
            ) => (
              <AccountPanelArticle key={i}>
                <TeachingArticleHeader>
                  <TeachingArticleHeaderText>
                    <h3>{teachingIntroHeading}</h3>
                    <p>{teachingIntroPreamble}</p>
                    {teachingIntroPdfShow && (
                      <TeachingDownloadLink
                        href={teachingIntroPdf.teachingIntroFile.publicURL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          Ladda ner {teachingIntroPdf.teachingIntroFileName}
                        </span>
                        <Icon icon={DownloadIcon} />
                      </TeachingDownloadLink>
                    )}
                  </TeachingArticleHeaderText>
                </TeachingArticleHeader>

                <TeachingExpandableInfoContainer>
                  {teachingIntroList.map(
                    (
                      {
                        teachingIntroListHeading,
                        teachingIntroListSubheading,
                        body,
                        teachingIntroListDescriptionImage,
                        altText,
                      },
                      i
                    ) => (
                      <AccountTogglePanel
                        key={i}
                        heading={teachingIntroListHeading}
                        subheading={teachingIntroListSubheading}
                        description={body}
                        descriptionImg={teachingIntroListDescriptionImage}
                        altText={altText}
                      />
                    )
                  )}
                </TeachingExpandableInfoContainer>
              </AccountPanelArticle>
            )
          )}
        </TeachingHeader>


      </TeachingContentWrapper>
    </>
  );
};
