import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import styled from "styled-components";
import { respondTo } from "../../styles";
import TabBg from "../../images/PMB_tab.png";
import TabBgSelected from "../../images/PMB_tab_selected.png";

export const StyledTabs = styled(Tabs)`
  margin: 0 auto;
`;

export const StyledTab = styled(Tab)`
  font-size: 14px;
  display: inline-block;
  list-style: none;
  padding: 26px 12px;
  cursor: pointer;
  width: 16%;
  //background: var(--mercury);
  //border: 6px solid #fffdf8;
  //box-sizing: border-box;
  //border-radius: 67px;
  text-align: center;
  color: var(--tabBtnColor);
  font-weight: 700;
  background-image: url(${TabBg});
  background-repeat: no-repeat;
  background-size: 100% 87px;
  display: flex;
  align-items: center;
  max-width: 287px;

  span {
    line-height: unset;
    display: inline-block;
    margin: 0 auto;
  }

  ${respondTo.xl`
    font-size: 20px;
  `}

  &.react-tabs__tab--selected {
    //background: var(--tabBtnColor);
    //border: 6px solid #fffdf8;
    //box-sizing: border-box;
    //-radius: 67px;
    color: var(--creamWhite);
    background-image: url(${TabBgSelected});
    //background-repeat: no-repeat;
  }

  &.react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }

  &.react-tabs__tab:focus {
    box-shadow: 0 0 5px hsl(208, 99%, 50%);
    border-color: hsl(208, 99%, 50%);
    outline: none;
  }

  &.react-tabs__tab:focus:after {
    content: "";
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
  }
`;

export const StyledTabList = styled(TabList)`
  margin: 0 0 50px;
  padding: 0 var(--mobilePadding);
  display: flex;
  width: 100%;

  height: 90px;

  justify-content: space-between;

  @media print {
    display: none;
  }
`;

export const StyledTabPanel = styled(TabPanel)`
  display: none;
  overflow: hidden;
  h2 {
    margin-bottom: 50px;
  }

  &.react-tabs__tab-panel--selected {
    display: block;
    padding: 0 var(--mobilePadding);
  }
`;

export const StyledTabPanelPedagogics = styled(TabPanel)`
  display: none;
  background-color: var(--mercury);
  overflow: hidden;

  h2 {
    margin-bottom: 0;
    position: relative;
    left: -75px;
    padding: 0 75px 50px;
    background-color: var(--accountBg);
    width: calc(100% + 150px);
  }

  &.react-tabs__tab-panel--selected {
    display: block;
    padding: 0 var(--mobilePadding);
  }
`;
