import React from "react";
import GameMapImage from "../../../../images/pmb_map_lock_2_full.jpg";
import GameLockImage from "../../../../images/lock.png";

import './GameLockMap.css'

const GameLockMap = (props) => {

  //console.log("currentLockNumber: " + props.currentLockNumber)

  let allLocks = document.getElementsByClassName("lock")
  var arr = Array.from(allLocks);

  if(props.currentLockNumber === 0 || props.currentLockNumber === undefined) {
    setLockVisibility(21)
  } else {
    setLockVisibility(props.currentLockNumber)
  }

  function clickedLock(e) {
    //console.log("clickedLock(e)", e.currentTarget.dataset.lock, e.currentTarget.classList)

    let localLockVal = parseInt(e.currentTarget.dataset.lock)

    if(!e.currentTarget.classList.contains("hidden")) {
      //console.log("supersize it!")
      localLockVal = localLockVal + 1
    }

    props.reportNewLockClick(localLockVal)

    setLockVisibility(localLockVal)
  }

  // function getTecherID() {
  //   props.teacherID("lisa")
  // }

  function setLockVisibility(lockFrom) {
    // if user clicks on the balloon and it's already active make 
    // sure to hide the lock and post 21 to the database

    arr.map(function (element) {
      element.classList.remove("hidden")
      const elementInt = parseInt(element.dataset.lock)
      const datasetInt = parseInt(lockFrom-1)
      if(elementInt <= datasetInt) {
        element.classList.add("hidden")
      }
      // if (element.dataset.lock === e.currentTarget.dataset.lock) {
      //   console.log("the winner is ", e.currentTarget.dataset.lock)
      // }
    })
  }

  //getTecherID();

  return (
    <>
      <div id="GameLockBox">

        <img src={GameMapImage} id="Map" useMap="#workmap" />

        <map name="workmap">
          <area target="" alt="Lås alla övningar" title="Lås alla övningar" onClick={clickedLock} data-lock="1" coords="1724,636,1856,791" shape="rect" />
          <area target="" alt="Lås upp sista övningen" title="Lås upp sista övningen" onClick={clickedLock} data-lock="21" coords="1490,129,1608,239" shape="rect" className="balloon" />
        </map>

        <img src={GameLockImage} onClick={clickedLock} alt="Lås" className="lock" id="lock_1" data-lock="1" />
        <img src={GameLockImage} onClick={clickedLock} alt="Lås" className="lock" id="lock_2" data-lock="2" />
        <img src={GameLockImage} onClick={clickedLock} alt="Lås" className="lock" id="lock_3" data-lock="3" />
        <img src={GameLockImage} onClick={clickedLock} alt="Lås" className="lock" id="lock_4" data-lock="4" />
        <img src={GameLockImage} onClick={clickedLock} alt="Lås" className="lock" id="lock_5" data-lock="5" />
        <img src={GameLockImage} onClick={clickedLock} alt="Lås" className="lock" id="lock_6" data-lock="6" />
        <img src={GameLockImage} onClick={clickedLock} alt="Lås" className="lock" id="lock_7" data-lock="7" />
        <img src={GameLockImage} onClick={clickedLock} alt="Lås" className="lock" id="lock_8" data-lock="8" />
        <img src={GameLockImage} onClick={clickedLock} alt="Lås" className="lock" id="lock_9" data-lock="9" />
        <img src={GameLockImage} onClick={clickedLock} alt="Lås" className="lock" id="lock_10" data-lock="10" />
        <img src={GameLockImage} onClick={clickedLock} alt="Lås" className="lock" id="lock_11" data-lock="11" />
        <img src={GameLockImage} onClick={clickedLock} alt="Lås" className="lock" id="lock_12" data-lock="12" />
        <img src={GameLockImage} onClick={clickedLock} alt="Lås" className="lock" id="lock_13" data-lock="13" />
        <img src={GameLockImage} onClick={clickedLock} alt="Lås" className="lock" id="lock_14" data-lock="14" />
        <img src={GameLockImage} onClick={clickedLock} alt="Lås" className="lock" id="lock_15" data-lock="15" />
        <img src={GameLockImage} onClick={clickedLock} alt="Lås" className="lock" id="lock_16" data-lock="16" />
        <img src={GameLockImage} onClick={clickedLock} alt="Lås" className="lock" id="lock_17" data-lock="17" />
        <img src={GameLockImage} onClick={clickedLock} alt="Lås" className="lock" id="lock_18" data-lock="18" />
        <img src={GameLockImage} onClick={clickedLock} alt="Lås" className="lock" id="lock_19" data-lock="19" />
        <img src={GameLockImage} onClick={clickedLock} alt="Lås" className="lock" id="lock_20" data-lock="20" />

      </div>

    </>
  );
};

export default GameLockMap;