import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { toHTML } from "../../../utils/helpers";
import {
  CounterIcon,
  ImageWrapperCircle,
  ImageWrapperRectangle,
  PanelList,
  PanelListItem,
  PanelListItemLink,
  PanelListItemText,
  TextEditor,
} from "../../common";
import {
  AccountPanelArticle,
  AccountPanelSection,
  AccountPanelSectionCurvedBottom,
  AccountPanelSectionWrapper,
  AccountPanelTopHeader,
} from "../AccountPanelLayout";

const PedagogicsListItemCounterContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  justify-content: center;
  align-items: center;
`;

const PedagogicsTextContainer = styled.div`
  overflow: hidden;
  h3 {
    font-size: 30px;
    line-height: 36px;
    color: var(--pmbBlack);
    margin-bottom: 12px;
  }
  h4 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    line-height: 22px;
  }
`;

const PedagogicsMethodDescription = styled.div`
  display: grid;
  grid-template-columns: auto 320px;
  width: 100%;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 76px;
  }

  ${PedagogicsTextContainer} {
    max-width: 900px;
    padding: 20px 0 0 35px;
  }

  ${ImageWrapperRectangle} {
    margin-top: 68px;
  }
`;

const MethodListCounterHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  h3 {
    margin-bottom: 0;
    font-size: 36px;
    line-height: 32px;
    color: var(--pmbBlack);
  }

  ${CounterIcon} {
    margin-right: 40px;
  }
`;

export const PedagogicsPanel = ({ linkList, methodListing }) => {
  return (
    <>
      <h2>Pedagogiken - introduktion</h2>
      <AccountPanelSectionWrapper counterName="PedagogicsMethodList">
        <AccountPanelTopHeader>
          <AccountPanelSectionCurvedBottom>
            <AccountPanelArticle>
              <PanelList counterName="PedagogicsLinkList" height="530px">
                {!!linkList &&
                  linkList.map(
                    ({ linkListHeading, linkListDescription }, i) => (
                      <PanelListItem key={i} listLength={linkList.length}>
                        <PanelListItemLink href={`#panel-method-${i + 1}`}>
                          <PedagogicsListItemCounterContainer left="-30px">
                            <CounterIcon counterName="PedagogicsLinkList" />
                          </PedagogicsListItemCounterContainer>

                          <PanelListItemText>
                            <h4>{linkListHeading}</h4>
                            <p>{linkListDescription}</p>
                          </PanelListItemText>
                        </PanelListItemLink>
                      </PanelListItem>
                    )
                  )}
              </PanelList>
            </AccountPanelArticle>
          </AccountPanelSectionCurvedBottom>
        </AccountPanelTopHeader>

        {!!methodListing &&
          methodListing.map(({ methodHeading, methodList }, i) => (
            <AccountPanelSection id={`panel-method-${i + 1}`} key={i}>
              <AccountPanelSectionCurvedBottom odd={i % 2 !== 0}>
                <AccountPanelArticle>
                  <MethodListCounterHeader>
                    <CounterIcon counterName="PedagogicsMethodList" />
                    <h3>{methodHeading}</h3>
                  </MethodListCounterHeader>

                  <PanelList height="500px">
                    {!!methodList &&
                      methodList.map(
                        (
                          {
                            methodListHeading,
                            methodListPreamble,
                            methodListPreambleImage,
                            altTextPreamble,
                          },
                          i
                        ) => (
                          <PanelListItem listLength={methodList.length} key={i}>
                            <PanelListItemLink
                              href={`#method-description-${i}-${methodListHeading.replaceAll(
                                " ",
                                ""
                              )}`}
                            >
                              <PedagogicsListItemCounterContainer>
                                <ImageWrapperCircle size="180px">
                                  {methodListPreambleImage && (
                                    <Img
                                      fluid={
                                        methodListPreambleImage.childImageSharp
                                          .fluid
                                      }
                                      alt={altTextPreamble}
                                    />
                                  )}
                                </ImageWrapperCircle>
                              </PedagogicsListItemCounterContainer>

                              <PanelListItemText>
                                <h3>{methodListHeading}</h3>
                                <p>{methodListPreamble}</p>
                              </PanelListItemText>
                            </PanelListItemLink>
                          </PanelListItem>
                        )
                      )}
                  </PanelList>
                  {!!methodList &&
                    methodList.map(
                      (
                        {
                          methodListHeading,
                          body,
                          methodDescriptionImage,
                          alttextDescription,
                        },
                        i
                      ) => (
                        <PedagogicsMethodDescription
                          id={`method-description-${i}-${methodListHeading.replaceAll(
                            " ",
                            ""
                          )}`}
                          key={i}
                        >
                          <PedagogicsTextContainer>
                            <h3>{methodListHeading}</h3>
                            <TextEditor
                              dangerouslySetInnerHTML={{ __html: toHTML(body) }}
                            />
                          </PedagogicsTextContainer>
                          <ImageWrapperRectangle width="320px">
                            {methodDescriptionImage && (
                              <Img
                                fluid={
                                  methodDescriptionImage.childImageSharp.fluid
                                }
                                alt={alttextDescription}
                              />
                            )}
                          </ImageWrapperRectangle>
                        </PedagogicsMethodDescription>
                      )
                    )}
                </AccountPanelArticle>
              </AccountPanelSectionCurvedBottom>
            </AccountPanelSection>
          ))}
      </AccountPanelSectionWrapper>
    </>
  );
};
