import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import { CounterIcon, Icon, ImageWrapperCircle } from "../../common";
import {
  AccountPanelArticle,
  AccountPanelSection,
  AccountPanelSectionCurvedBottom,
  AccountPanelSectionWrapper,
} from "../AccountPanelLayout";
import DownloadIcon from "../../../images/PMB_download_icon.png";

const LibraryPanelSectionWrapper = styled(AccountPanelSectionWrapper)`
  counter-reset: LibrarySection;

  h3 {
    font-size: 20px;
  }

  ${AccountPanelSection} {
    ${AccountPanelArticle} {
      padding: 0 90px;
    }

    &:first-of-type {
      ${AccountPanelArticle} {
        padding: 90px 90px;
      }
    }
  }
`;

const LibraryArticleHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  h2 {
    color: black;
    font-size: 30px;
    padding-left: 40px;
    margin-bottom: 0;
  }
`;

const LibraryListWrapper = styled.div`
  background-color: white;
  padding: 32px 36px 24px 28px;
  margin-left: 124px;
`;

const LibraryList = styled.ul`
  list-style: none;
`;

const LibraryListItem = styled.li`
  margin-bottom: 24px;
`;

const LibraryListItemLink = styled.a`
  display: grid;
  grid-template-columns: 48px auto 44px;
  text-decoration: none;
  color: black;
  font-size: 20px;

  span {
    align-self: center;
    padding-left: 36px;
  }

  ${Icon} {
    position: unset;
  }
`;

const LibraryListFullFile = styled.div`
  margin-bottom: 50px;
  h3 {
    margin-bottom: 16px;
  }
`;

const LibraryDownloadLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  font-size: 20px;

  ${Icon} {
    position: unset;
    padding-right: 40px;
  }

  span {
    padding-right: 20px;
  }
`;

export const LibraryPanel = ({ accountLibrary }) => {
  console.log(accountLibrary);
  return (
    <>
      <h2>Bibliotek</h2>
      <LibraryPanelSectionWrapper>
        {!!accountLibrary &&
          accountLibrary.map(
            (
              {
                accountLibraryHeading,
                accountLibraryFullFileName,
                accountLibraryFullFile,
                accountLibraryFileList,
              },
              i
            ) => (
              <AccountPanelSection key={i}>
                <AccountPanelSectionCurvedBottom odd={i % 2 !== 0}>
                  <AccountPanelArticle>
                    <LibraryArticleHeader>
                      <CounterIcon counterName="LibrarySection" />
                      <h2>{accountLibraryHeading}</h2>
                    </LibraryArticleHeader>
                    <LibraryListWrapper>
                      {!!accountLibraryFullFile && (
                        <LibraryListFullFile margin="0 0 50px">
                          <LibraryDownloadLink
                            href={accountLibraryFullFile.publicURL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>
                              Ladda ner {accountLibraryFullFileName}(pdf)
                            </span>{" "}
                            <Icon icon={DownloadIcon} size="44px" />
                          </LibraryDownloadLink>
                        </LibraryListFullFile>
                      )}

                      <LibraryList>
                        {!!accountLibraryFileList &&
                          accountLibraryFileList.map(
                            (
                              {
                                accountLibraryFileName,
                                accountLibraryFile,
                                accountLibraryImg,
                                altText,
                              },
                              i
                            ) => (
                              <LibraryListItem key={i}>
                                <LibraryListItemLink
                                  href={accountLibraryFile.publicURL}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <ImageWrapperCircle size="48px">
                                    {!!accountLibraryImg && (
                                      <Img
                                        fluid={
                                          accountLibraryImg.childImageSharp
                                            .fluid
                                        }
                                        alt={altText}
                                      />
                                    )}
                                  </ImageWrapperCircle>

                                  <span>{accountLibraryFileName}</span>
                                  <Icon icon={DownloadIcon} size="44px" />
                                </LibraryListItemLink>
                              </LibraryListItem>
                            )
                          )}
                      </LibraryList>
                    </LibraryListWrapper>
                  </AccountPanelArticle>
                </AccountPanelSectionCurvedBottom>
              </AccountPanelSection>
            )
          )}
      </LibraryPanelSectionWrapper>
    </>
  );
};
