import React, { useEffect, useState, useContext, useRef } from "react";
import UserContext from "../../../../context";
import CloseX from "../../../../images/close_x.png";
import Printer from "../../../../images/PMB_printer.png";
import { TextInput, IconButton, TextEditor } from "../../../common";
import { AccountButton } from "../../AccountButton";
import useFirebase from "../../../../../firebase/useFirebase";
import axios from "axios";
import Modal from "../../../common/Modal/Modal";
import { UserProgress } from "../../UserProgress";
import CustomRadioAndCheckbox from "../../../common/CustomRadioAndCheckbox";
import GameLockMap from "./GameLockMap";
import {
  ErrorAndInfoBox,
  MyUsersCounter,
  MyUsersHeading,
  MyUsersInfobox,
  MyUsersPanelArticle,
  MyUsersTable,
  MyUsersTableFooter,
  MyUsersTableRow,
  MyUserTableHead,
} from "./MyUsersPanelComponents";
import UserTableRow from "./UserTableRow";
import { toHTML } from "../../../../utils/helpers";

const MyUsersPanel = ({ myUsers, myTeacherID }) => {
  const firebase = useFirebase();
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({});
  const [totalUsers, setTotalUsers] = useState();
  const [showAddUser, setShowAddUser] = useState(false);
  const [currentUser, setCurrentUser] = useState(false);
  const [maxLicenses, setMaxLicenses] = useState(null);
  const { subscriber } = useContext(UserContext);
  const [newGamerError, setNewGamerError] = useState("");
  const modalRef = useRef();
  const [userProgress, setUserProgress] = useState();
  const [disableAddUser, setDisableAddUser] = useState(true);
  const passwordRef = useRef();
  const userNameRef = useRef();
  const [pupils, setPupils] = useState([]);
  // const [gameLock, setGameLock] = useState([]);

  //let teacherDbRef;  //     getTeacherDbRef();
  //let pupilsDbRef;

  // console.log("myTeacherID: ", {myTeacherID});

  useEffect(() => {
    if (firebase) {
      const user = firebase.auth().currentUser;
      setCurrentUser(user);
    }
  }, [firebase]);

  useEffect(() => {
    let source = axios.CancelToken.source();
    if (currentUser) {
      axios
        .get("/.netlify/functions/getGamersBySubscriberId", {
          params: { uuid: currentUser.uid },
          cancelToken: source.token,
        })
        .then((resp) => {
          setUsers(Object.values(resp.data))
          //pupilsDbRef = (Object.keys(resp.data))
          setPupils(Object.keys(resp.data))
          //console.log("pupilsDbRef:" + pupilsDbRef)
        })
        .catch((e) => {
          if (axios.isCancel(e)) {
            console.log(`request cancelled:${e.message}`);
          } else {
            console.log("another error happened:" + e.message);
          }
        });
    }

    return () => source.cancel("cleanup");
  }, [setUsers, currentUser]);

  useEffect(() => {
    if (subscriber) {
      setMaxLicenses(subscriber.subscriptionType === "school" ? 32 :
                subscriber.subscriptionType === "school20" ? 21 :
      					 subscriber.subscriptionType === "parent" ? 2 :
      					 	subscriber.subscriptionType === "pedagog" ? 8 :
                   subscriber.subscriptionType === "lararwebb" ? 1 :
      					 		subscriber.subscriptionType === "private" ? 3 : null);
    }
  }, [subscriber]);
  useEffect(() => {
    setTotalUsers(users.length);
  }, [users.length]);

  // useEffect(() => {
  //   console.log("When does this run?", subscriber.gameLock)
  //   subscriber.gameLock
  //   setGameLock(subscriber.gameLock);
  // }, [gameLock]);

  const handleUserInput = (e) => {
    e.persist();

    setNewUser((currentValues) => ({
      ...currentValues,
      [e.target.name]:
        e.target.name === "isTeacher" ? e.target.checked : e.target.value,
      progress: 1,
    }));

    passwordRef.current.value.length >= 6 &&
    userNameRef.current.value.length >= 6
      ? setDisableAddUser(false)
      : setDisableAddUser(true);
  };

  const addUser = async () => {
    if (!disableAddUser) {
      try {
        setNewGamerError("");
        await axios.post(`/.netlify/functions/addGamer`, {
          userName: newUser.userName,
          password: newUser.password,
          subscriberID: currentUser.uid,
          isTeacher: newUser.isTeacher,
          gameLock: subscriber.gameLock
        });
        users.push(newUser);
        // console.log(newUser)
        setTotalUsers(users.length);
        setShowAddUser(false);
        setDisableAddUser(true);
      } catch (e) {
        let errorText = "";
        if (
          e?.response?.data?.message ===
          "The email address is already in use by another account."
        )
          errorText =
            "Den här användaren finns redan, vänligen välj ett annat namn.";

        if (
          e?.response?.data?.message ===
          "Reference.push failed: first argument contains undefined in property 'GameUsers.password'"
        )
          errorText = "Ange ett lösenord.";

        if (
          e?.response?.data?.message ===
          "The password must be a string with at least 6 characters."
        )
          errorText = "Lösenordet måste bestå av minst 6 bokstäver";

        setNewGamerError(errorText);
      }
    }
  };


  // const getTeacherDbRef = async () => {
  //   let source = axios.CancelToken.source();
  //   if (currentUser) {
  //     axios
  //       .get("/.netlify/functions/getTeacherBySubscriberId", {
  //         params: { uuid: currentUser.uid },
  //         cancelToken: source.token,
  //       })
  //       .then((resp) => {
  //         console.log("teacher DbRef: ", Object.keys(resp.data)[0])
  //       })
  //       .catch((e) => {
  //         if (axios.isCancel(e)) {
  //           console.log(`request cancelled:${e.message}`);
  //         } else {
  //           console.log("another error happened:" + e.message);
  //         }
  //       });
  //   }
  // }

  const updateGameLock = async (e) => {
    // if (!disableAddUser) {
      // console.log("from updateGameLock: ", e)
      try {
        setNewGamerError("");
        await axios.put(`/.netlify/functions/setGameLockForAll?key=` + subscriber.gameLock, {
          e
        });
        // users.push(newUser);
        // setTotalUsers(users.length);
        // setShowAddUser(false);
        // setDisableAddUser(true);
      } catch (e) {
        let errorText = "";
        if (
          e?.response?.data?.message ===
          "The email address is already in use by another account."
        )
          errorText =
            "Den här användaren finns redan, vänligen välj ett annat namn.";

        if (
          e?.response?.data?.message ===
          "Reference.push failed: first argument contains undefined in property 'GameUsers.password'"
        )
          errorText = "Ange ett lösenord.";

        if (
          e?.response?.data?.message ===
          "The password must be a string with at least 6 characters."
        )
          errorText = "Lösenordet måste bestå av minst 6 bokstäver";

        setNewGamerError(errorText);
      }
    //}
  };
  
  const updateLockInDb = ( e ) => {
    // console.log("new lock to save to db:" + e);
    // console.log("these users will be updated, teacher: ", {myTeacherID}.myTeacherID);
    // console.log("Pupils: ", {pupils}) // pupilsDbRef);
    subscriber.gameLock = e;
    let allUsers = [];
    allUsers.push({myTeacherID}.myTeacherID);

    {pupils.map((item, i) => 
      allUsers.push(item)
    )}




    // let i = 0;
    // Object.values({pupils}).forEach(element => {
    //   console.log("i", i)
    //   allUsers.push(element);
    //   i++
    // });

    // console.log("allUSers", allUsers)
    updateGameLock(allUsers);
  }


  const deleteUser = async (userIndex) => {
    try {
      const userToDelete = users[userIndex];
      await axios.delete(`/.netlify/functions/deleteGamer`, {
        params: { userName: userToDelete.userName },
      });
      const updatedUsers = users.filter((_, index) => index !== userIndex);
      setUsers(updatedUsers);
      setTotalUsers(users.length);
    } catch (e) {
      console.log({ e });
    }
  };

  const openModalHandler = (userIndex) => {
    const userProgressModal = users.filter((_, index) => index === userIndex);
    setUserProgress(userProgressModal);
    modalRef.current.openModal();
  };

  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);

  useEffect(() => {
    let sortedUsers = users.sort((a, b) => {
      if (a.isTeacher && b.isTeacher) {
        return 1;
      }
      if (a.isTeacher && !b.isTeacher) {
        return -1;
      }
      return 0;
    });
    setUsers(sortedUsers);
  }, [users, setUsers]);

  //getTeacherDbRef();

  return (
    <>
      <MyUsersHeading>Mina användare</MyUsersHeading>
      <MyUsersCounter>
        <span>{!!totalUsers ? totalUsers : 0}</span> av{" "}
        <span>{maxLicenses}</span> användare
      </MyUsersCounter>
      <Modal ref={modalRef}>
        <UserProgress user={userProgress} />
      </Modal>
      <MyUsersPanelArticle>
        <MyUsersTable>
          <MyUserTableHead>
            <MyUsersTableRow borderHeight="14px" panelHeight="58px">
              <th>Användare</th>
              <th>Progress</th>
              <th>Lösenord</th>
              {/* <th>Lås</th> */}
              <th>
                <span className="sr-only">Lärare</span>
              </th>
              <th>
                <span className="sr-only">Radera användare</span>
              </th>
            </MyUsersTableRow>
          </MyUserTableHead>
          <tbody>
            {!!users &&
              users.map(({ userName, progress, password, isTeacher, gameLock }, i) => (
                <UserTableRow
                  key={i}
                  userName={userName}
                  password={password}
                  isTeacher={isTeacher}
                  deleteUser={deleteUser}
                  index={i}
                  progress={progress}
                  openModalHandler={openModalHandler}
                  gameLock={gameLock}
                />
              ))}

            {!!showAddUser && (
              <>
                <MyUsersTableRow
                  borderHeight="16px"
                  panelHeight="120px"
                  bgColor="var(--mercury)"
                >
                  <td>
                    <TextInput
                      type="text"
                      name="userName"
                      ref={userNameRef}
                      value={users.userName}
                      onChange={handleUserInput}
                      placeholder="Välj användarnamn, minst 6 tecken"
                      customWidth="100%"
                    />
                  </td>

                  <td>- - -</td>

                  <td>
                    <TextInput
                      type="text"
                      name="password"
                      ref={passwordRef}
                      value={users.password}
                      onChange={handleUserInput}
                      placeholder="Ange ett lösenord, minst 6 tecken"
                      customWidth="100%"
                    />
                  </td>
                  <td>
                    <CustomRadioAndCheckbox
                      label="Lärare"
                      type="checkbox"
                      name="isTeacher"
                      value={users.isTeacher}
                      onChange={handleUserInput}
                    ></CustomRadioAndCheckbox>
                  </td>
                  <td>
                    <AccountButton
                      btnColor="white"
                      disabled={disableAddUser}
                      onClick={addUser}
                    >
                      Klar
                    </AccountButton>
                    <IconButton
                      icon={CloseX}
                      top="-20px"
                      right="-20px"
                      bgColor="white"
                      borderRadius="100%"
                      onClick={() => setShowAddUser((prev) => !prev)}
                    />
                  </td>
                </MyUsersTableRow>
                <MyUsersTableRow bgColor="var(--accountBg)">
                  <td>
                    <ErrorAndInfoBox>
                      {newGamerError ? (
                        <p>{newGamerError}</p>
                      ) : (
                        <p>
                          Viktigt! Se till att barnens användarnamn inte kan
                          kopplas direkt till personen. Använd bara förnamn
                          eller alias. Läs mer om hur vi behandlar dina GDPR
                          uppgifter
                        </p>
                      )}
                    </ErrorAndInfoBox>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <AccountButton
                      btnColor="white"
                      onClick={() => setShowAddUser(false)}
                      disabled={users.length >= maxLicenses}
                    >
                      Avbryt
                    </AccountButton>
                  </td>
                </MyUsersTableRow>
              </>
            )}
          </tbody>
        </MyUsersTable>

        <MyUsersTableFooter>
          <IconButton icon={Printer} onClick={window.print}>
            <span>Skriv ut som lista</span>
          </IconButton>
        </MyUsersTableFooter>
        {!showAddUser && (
          <AccountButton
            btnColor="white"
            onClick={() => {
              setShowAddUser(true);
              setNewUser({});
            }}
            disabled={users.length >= maxLicenses}
          >
            Lägg till användare
          </AccountButton>
        )}
        <MyUsersInfobox>
          <h2>Information</h2>
          <TextEditor
            dangerouslySetInnerHTML={{ __html: toHTML(myUsers.body) }}
          />
        </MyUsersInfobox>

      </MyUsersPanelArticle>

      <GameLockMap currentLockNumber={subscriber.gameLock} reportNewLockClick={(newLockNumber) => {
          //console.log("newLockNumber: ", newLockNumber);
          updateLockInDb(newLockNumber);
        }}/>

    </>
  );
};

export default MyUsersPanel;
