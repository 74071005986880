import styled from "styled-components";
import { AccountButton } from "../../AccountButton";
import { TextInput, DeleteButton, IconButton } from "../../../common";
import { respondTo } from "../../../../styles";

export const MyUsersPanelArticle = styled.article`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 58px 98px 270px;
  row-gap: 15px;
  padding-bottom: 90px;

  ${AccountButton} {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    justify-self: end;
    margin-bottom: 40px;
  }
`;

export const MyUsersHeading = styled.h2`
  margin-bottom: 34px;
`;

export const MyUsersCounter = styled.p`
  color: var(--creamWhite);
  text-align: right;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
`;

export const MyUsersTable = styled.table`
  --borderHeight: 36px;
  table-layout: fixed;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  border-collapse: collapse;

  ${TextInput} {
    border: 2px solid black;
    &::placeholder {
      font-size: 16px;
    }
  }
`;

export const MyUserTableHead = styled.thead`
  font-weight: 700;

  tr {
    border-bottom: 36px solid var(--accountBg);
    height: calc(58px + 36px);
    text-align: left;
    background-color: white;
    font-size: 24px;

    @media print {
      border-color: white;
    }
  }

  th {
    &:nth-child(1) {
      width: 25%;
      padding-left: 30px;
    }

    &:nth-child(2) {
      width: 17%;
      text-align: center;

      @media print {
        display: none;
      }
    }

    &:nth-child(3) {
      width: 29%;
      padding-left: 40px;
    }
    &:nth-child(4) {
      width: 14%;
    }
    &:nth-child(5) {
      width: 15%;
    }
  }
`;

export const MyUsersTableRow = styled.tr`
  border-bottom: ${(props) => props.borderHeight || "5px"} solid
    var(--accountBg);
  height: calc(
    ${(props) => props.panelHeight || "5px"} +
      ${(props) => props.borderHeight || "5px"}
  );
  text-align: left;
  background-color: ${(props) => props.bgColor || "white"};
  font-size: 24px;

  @media print {
    border-color: white;
  }

  td {
    ${AccountButton} {
      margin-bottom: 0;
    }

    &:first-of-type {
      padding-left: 30px;
    }

    &:nth-child(2) {
      @media print {
        display: none;
      }
    }

    &:nth-child(2),
    &:nth-child(4) {
      text-align: center;
    }

    &:nth-child(3) {
      padding-left: 40px;
    }

    &:last-of-type {
      padding-right: 30px;
      position: relative;

      ${AccountButton},
      ${DeleteButton} {
        float: right;
      }
    }
  }
`;

export const MyUsersTableFooter = styled.div`
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  display: flex;
  justify-content: flex-start;
  color: var(--creamWhite);

  ${IconButton} {
    position: static;
    padding-left: 40px;

    span {
      width: 180px;
      color: var(--creamWhite);
      font-size: 16px;
      display: inline-block;
    }
  }

  @media print {
    display: none;
  }
`;

export const MyUsersInfobox = styled.div`
  grid-column: 1 / 2;
  
  background-color: var(--tabBtnColor);
  color: var(--creamWhite);
  padding: 20px 29px 0;
  font-size: 20px;

  h2 {
    font-size: 24px;
    margin-bottom: 0px;
    color: var(--creamWhite);
  }

  @media print {
    display: none;
  }
`;

export const ErrorAndInfoBox = styled.div`
  height: 100%;
  width: 100%;
  background-color: var(--pmbChestnut);
  color: white;
  padding: 12px;
  font-size: 16px;
`;

export const DeleteUserBox = styled.div`
  height: 94px;
  width: 900px;
  background-color: white;
  z-index: 5;
  position: absolute;
  right: 0;
  bottom: 0;
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;

  ${respondTo.xl`
    width: 1050px;
  `}

  p {
    display: inline-block;
    color: var(--pmbCedarWood);
    font-weight: 700;
  }
`;
