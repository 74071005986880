import React, { useState, useEffect, useCallback, useContext } from "react";
import { navigate, Link, graphql } from "gatsby";
import axios from "axios";
import { format } from "date-fns";
import UserContext from "../context";
import SEO from "../components/SEO";
import {
  StyledTab,
  StyledTabList,
  StyledTabPanel,
  StyledTabPanelPedagogics,
  StyledTabs,
} from "../components/Tabs";
import {
  MyAccountPanel,
  TeachingMaterialsPanel,
  PedagogicsPanel,
  LibraryPanel,
  AccountFaqPanel,
} from "../components/Account/AccountPanels";
import MyUsersPanel from "../components/Account/AccountPanels/MyUsersPanel/MyUsersPanel";
import {
  AccountSection,
  AccountSectionLogo,
  AccountTopNav,
  AccountScreenWidthInfo,
  AccountSubscriptionWarning,
} from "../components/Account";

import useFirebase from "../../firebase/useFirebase";

const AccountPageTemplate = ({
  title,
  myAccount,
  myUsers,
  linkList,
  methodListing,
  teachingTopImageText,
  teachingIntro,
  // teachingListing,
  accountFaq,
  accountContact,
  accountLibrary,
}) => {
  const [user, setUser] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const { subscriber, setSubscriber } = useContext(UserContext);
  const firebase = useFirebase();
  const [endOfDays, setEndOfDays] = useState();
  const [
    subscriptionExpirationWarning,
    setSubscriptionExpirationWarning,
  ] = useState(false);

  const fetchSubscriber = useCallback(
    async (uuid) => {
      const { data: subscriber } = await axios.get(
        `/.netlify/functions/getSubscriberByUuid?uuid=${uuid}`
      );      
      // console.log("subscribersubscribersubscriber", subscriber)
      setSubscriber(subscriber);
    },
    [setSubscriber]
  );

  useEffect(() => {
    if (!firebase) return;
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
      fetchSubscriber(user.uid);
    });

    return () => setUser(null);
  }, [firebase, fetchSubscriber]);

  const handleSignOut = (e) => {
    e.preventDefault();
    firebase.auth().signOut();
    setUser(null);
    navigate("/login");
  };

  const tabs = [
    "Mina uppgifter",
    "Spelanvändare",
    "Kom igång",
    "Bibliotek",
    "Pedagogik",
    "FAQ och kontakt",
  ];

  const myUsersShortcut = () => {
    setTabIndex(1);
  };

  useEffect(() => {
    if (!subscriber) return;

    const oneWeek = 1000 * 3600 * 24 * 7 * 1; // one week in milliseconds, * 2 for two etc
    const today = Date.parse(format(new Date(), "yyyy-MM-dd"));
    const endDate = Date.parse(subscriber.endOfSubscriptionDate);
    setEndOfDays(endDate);

    if (today + oneWeek >= endOfDays) {
      setSubscriptionExpirationWarning(true);
    } else {
      setSubscriptionExpirationWarning(false);
    }
  }, [setEndOfDays, setSubscriptionExpirationWarning, endOfDays, subscriber]);

  if (user === null) return <Link to="/login">Logga in</Link>;
  if (user)
    return (
      <>
        <SEO title={title} />
        <AccountScreenWidthInfo>
          <h1>
            Inloggat läge fungerar bara att använda på skärmstorlekar över
            1200px.
          </h1>
        </AccountScreenWidthInfo>
        <AccountSection>
          <AccountSubscriptionWarning
            showWarning={subscriptionExpirationWarning}
            date={subscriber.endOfSubscriptionDate}
          />

          <AccountTopNav signOut={handleSignOut} />
          <AccountSectionLogo />
          <h1 className="sr-only">Inloggat läge</h1>
          <StyledTabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            <StyledTabList>
              {tabs.map((tab, i) => (
                <StyledTab key={i}>
                  <span>{tab}</span>
                </StyledTab>
              ))}
            </StyledTabList>
            <StyledTabPanel>
              <MyAccountPanel
                user={user}
                subscriberData={subscriber}
                myAccount={myAccount}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <MyUsersPanel myUsers={myUsers} myTeacherID={subscriber.keyID} />
            </StyledTabPanel>
            <StyledTabPanel>
              <TeachingMaterialsPanel
                teachingIntro={teachingIntro}
                // teachingListing={teachingListing}
                teachingTopImageText={teachingTopImageText}
                myUsersShortcut={myUsersShortcut}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <LibraryPanel accountLibrary={accountLibrary} />
            </StyledTabPanel>
            <StyledTabPanelPedagogics>
              <PedagogicsPanel
                linkList={linkList}
                methodListing={methodListing}
              />
            </StyledTabPanelPedagogics>
            <StyledTabPanel>
              <AccountFaqPanel
                accountFaq={accountFaq}
                accountContact={accountContact}
              />
            </StyledTabPanel>
          </StyledTabs>
        </AccountSection>
      </>
    );
};

const AccountPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <AccountPageTemplate
      title={frontmatter.title}
      myAccount={frontmatter.myAccount}
      myUsers={frontmatter.myUsers}
      linkList={frontmatter.linkList}
      methodListing={frontmatter.methodListing}
      teachingIntro={frontmatter.teachingIntro}
      // teachingListing={frontmatter.teachingListing}
      accountFaq={frontmatter.accountFaq}
      accountContact={frontmatter.accountContact}
      accountLibrary={frontmatter.accountLibrary}
      teachingTopImageText={frontmatter.teachingTopImageText}
    />
  );
};

export default AccountPage;

export const pageQuery = graphql`
  query AccountPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        myAccount {
          body
        }
        myUsers {
          body
        }
        linkList {
          linkListHeading
          linkListDescription
        }

        methodListing {
          methodHeading
          methodList {
            methodListHeading
            methodListPreamble
            body
            methodListPreambleImage {
              childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            altTextPreamble
            methodDescriptionImage {
              childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            altTextDescription
          }
        }
        teachingIntro {
          teachingIntroHeading
          teachingIntroPreamble
          teachingIntroPdfShow
          teachingIntroPdf {
            teachingIntroFileName
            teachingIntroFile {
              id
              publicURL
              name
            }
          }

          teachingIntroList {
            teachingIntroListHeading
            teachingIntroListSubheading
            body
            teachingIntroListDescriptionImage {
              childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            altText
          }
        }
        teachingTopImageText

        accountFaq {
          accountFaqQuestion
          accountFaqAnswer
        }
        accountContact {
          accountContactPhone
          accountContactEmail
        }
        accountLibrary {
          accountLibraryHeading
          accountLibraryFullFileName
          accountLibraryFullFile {
            publicURL
            name
          }
          accountLibraryFileList {
            accountLibraryFileName
            accountLibraryFile {
              publicURL
              name
            }
            accountLibraryImg {
              childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            altText
          }
        }
      }
    }
  }
`;
