import React, { useState } from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import { AccountLink } from "../Account";
import {
  IconButton,
  ImageWrapperCircle,
  ImageWrapperSquare,
  TextEditor,
} from "../common";
import { respondTo } from "../../styles";
import CloseX from "../../images/close_x.png";
import { AccountButton } from "./AccountButton";
import { toHTML } from "../../utils/helpers";

const AccountTogglePanelWrapper = styled.div`
  margin-bottom: 60px;

  h4 {
    font-size: 20px;
    line-height: 28px;
  }

  p {
    font-size: 16px;
    line-height: 22px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const AccountTogglePanelInner = styled.div`
  display: grid;
  grid-template-columns: 113px auto 330px;
  grid-template-rows: 100%;
  background-color: white;
  height: 72px;
  position: relative;
  margin: 0 12px 12px 40px;

  ${AccountLink} {
    grid-column: 3 / 4;
  }
`;

const TogglePanelImage = styled.div`
  position: absolute;
  left: -30px;
  top: -30px;
`;

const TogglePanelButton = styled.button`
  grid-column: 2 / 3;
  background-color: transparent;
  border: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  i {
    height: 30px;
    width: 70px;
    padding-left: 10px;

    &:before {
      content: "";
      background-color: var(--pmbBlack);
      display: inline-block;
      height: 25px;
      left: 0;
      position: relative;
      top: 0;
      transform: rotate(-65deg);
      vertical-align: top;
      width: 3px;
      transition: transform 0.2s ease-in-out;
    }

    &:after {
      content: "";
      background-color: var(--pmbBlack);
      display: inline-block;
      height: 25px;
      left: 18px;
      position: relative;
      top: 0;
      transform: rotate(65deg);
      vertical-align: top;
      width: 3px;
      transition: transform 0.2s ease-in-out;
    }

    &.show {
      &:before {
        transform: rotate(-105deg);
      }

      &:after {
        transform: rotate(105deg);
      }
    }
  }
`;

const TogglePanelButtonText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding: 12px 0 12px 40px;
  text-align: left;

  p {
    max-width: 420px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    ${respondTo.xl`
      max-width: 700px;
    `}
  }
`;

const TogglePanelLink = styled.div`
  padding: 12px 0;
  align-self: center;
  justify-self: center;
`;

const TogglePanelDescription = styled.div`
  display: none;
  margin: 0 140px;
  padding: 45px 80px 45px 60px;
  background-color: white;
  position: relative;

  &.show {
    display: grid;
    grid-template-columns: auto 170px;
    grid-column-gap: 36px;
  }

  ${ImageWrapperSquare} {
    align-self: self-start;
  }
`;

const AccountTogglePanel = ({
  image,
  heading,
  subheading,
  description,
  descriptionImg,
  altText,
  altTextLarge,
  showLink,
  link,
  shortcut,
  showShortcut,
}) => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <AccountTogglePanelWrapper>
      <AccountTogglePanelInner>
        {image && (
          <TogglePanelImage>
            <ImageWrapperCircle size="113px">
              <Img fluid={image.childImageSharp.fluid} alt={altText} />
            </ImageWrapperCircle>
          </TogglePanelImage>
        )}
        <TogglePanelButton onClick={() => setShowInfo((prev) => !prev)}>
          <i className={showInfo ? "show" : null}></i>

          <TogglePanelButtonText>
            {heading && <h4>{heading}</h4>}
            {subheading && <p>{subheading}</p>}
          </TogglePanelButtonText>
        </TogglePanelButton>
        <TogglePanelLink>
          {showLink && <AccountLink href={link}>Visa i spelet</AccountLink>}
          {showShortcut && (
            <AccountButton onClick={shortcut}>
              Till mina användare
            </AccountButton>
          )}
        </TogglePanelLink>
      </AccountTogglePanelInner>
      {description && (
        <TogglePanelDescription className={showInfo ? "show" : null}>
          <TextEditor
            dangerouslySetInnerHTML={{ __html: toHTML(description) }}
          />
          {!!descriptionImg && (
            <ImageWrapperSquare size="170px">
              <Img
                fluid={descriptionImg.childImageSharp.fluid}
                alt={altTextLarge}
              />
            </ImageWrapperSquare>
          )}
          <IconButton
            icon={CloseX}
            top="20px"
            right="20px"
            onClick={() => setShowInfo(false)}
          />
        </TogglePanelDescription>
      )}
    </AccountTogglePanelWrapper>
  );
};

export default AccountTogglePanel;
