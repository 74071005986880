import React, { useState } from "react";
import { ContentWrapper, TextEditor, TextInput } from "../../common";
import { AccountButton } from "../AccountButton";
import { LoginInfo } from "../../Login";
import { toHTML } from "../../../utils/helpers";
import {
  MyAccountInformation,
  MyAccountPanelArticle,
  MyAccountPanelDetails,
} from "./MyAccountPanelComponents";

export const MyAccountPanel = ({ user, subscriberData, myAccount }) => {
  const [currentUser, setCurrentUser] = useState(user.displayName);
  const [currentUserEmail, setCurrentUserEmail] = useState(user.email);
  const [showChangeUserName, setShowChangeUserName] = useState(false);
  const [showChangeUserEmail, setShowChangeUserEmail] = useState(false);
  const [showChangeUserPassword, setShowChangeUserPassword] = useState(false);
  const [newUserInfo, setNewUserInfo] = useState({
    userName: user.displayName,
    email: user.email,
    password: "",
  });
  const [changePasswordError, setChangePasswordError] = useState("");

  const changeDisplayName = (e) => {
    e.preventDefault();
    user
      .updateProfile({
        displayName: newUserInfo.userName,
      })
      .then(() => setCurrentUser(newUserInfo.userName))
      .catch((err) => console.error(err));
  };

  const changeEmail = (e) => {
    e.preventDefault();
    user
      .updateEmail(newUserInfo.email)
      .then(() => setCurrentUserEmail(newUserInfo.email))
      .catch((err) => console.error(err));
  };

  const changePassword = (e) => {
    e.preventDefault();
    user
      .updatePassword(newUserInfo.password)
      .then(() => setNewUserInfo(newUserInfo.password))
      .catch((err) => {
        let errorText = "";
        if (err.code === "auth/requires-recent-login")
          errorText =
            "Det gick inte att byta lösenord. Testa att logga ut och logga in igen. Om du glömt ditt lösenord, klicka på länken på inloggningssidan.";
        setChangePasswordError(errorText);
        console.error(err);
      });
  };

  const handleNewUserInfo = (e) => {
    e.persist();
    setNewUserInfo((currentValues) => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <h2>Mina uppgifter</h2>
      <MyAccountPanelArticle>
        <ContentWrapper>
          <MyAccountPanelDetails bgColor={showChangeUserName}>
            {!showChangeUserName ? (
              <>
                <div className="box">
                  <h3>Namn:</h3>
                </div>
                <div className="box">
                  <p>{currentUser}</p>
                </div>
                <div className="box">
                  <AccountButton
                    type="submit"
                    onClick={() => {
                      setShowChangeUserName((prev) => !prev);
                    }}
                  >
                    Ändra
                  </AccountButton>
                </div>
              </>
            ) : (
              <>
                <div className="box">
                  <label htmlFor="myAccountFirstName">Namn: </label>
                </div>
                <div className="box">
                  <TextInput
                    type="text"
                    name="userName"
                    id="myAccountFirstName"
                    value={newUserInfo.userName}
                    onChange={handleNewUserInfo}
                  />
                </div>
                <div className="box">
                  <AccountButton
                    type="submit"
                    disabled={newUserInfo.userName.length === 0}
                    onClick={(e) => {
                      changeDisplayName(e);
                      setShowChangeUserName(false);
                    }}
                  >
                    Klar
                  </AccountButton>
                </div>
              </>
            )}
          </MyAccountPanelDetails>

          <MyAccountPanelDetails bgColor={showChangeUserEmail}>
            {!showChangeUserEmail ? (
              <>
                <div className="box">
                  <h3>E-post:</h3>
                </div>
                <div className="box">
                  <p>{currentUserEmail}</p>
                </div>
                <div className="box">
                  <AccountButton
                    onClick={() => {
                      setShowChangeUserEmail((prev) => !prev);
                    }}
                  >
                    Ändra
                  </AccountButton>
                </div>
              </>
            ) : (
              <>
                <div className="box">
                  <label htmlFor="myAccountEmail">E-post: </label>
                </div>
                <div className="box">
                  <TextInput
                    type="email"
                    name="email"
                    id="myAccountEmail"
                    value={newUserInfo.email}
                    onChange={handleNewUserInfo}
                  />
                </div>
                <div className="box">
                  <AccountButton
                    type="submit"
                    disabled={newUserInfo.email.length === 0}
                    onClick={(e) => {
                      changeEmail(e);
                      setShowChangeUserEmail(false);
                    }}
                  >
                    Klar
                  </AccountButton>
                </div>
              </>
            )}
          </MyAccountPanelDetails>

          <MyAccountPanelDetails>
            <div className="box">
              <h3>Abonnemangstyp:</h3>
            </div>
            <div className="box">
              <p>
                {subscriberData.subscriptionType === "school" ? "Lärarwebb + 30 elever" :
                subscriberData.subscriptionType === "school20" ? "Lärarwebb + 20 elever" :
      					 subscriberData.subscriptionType === "parent" ? "Förälder + barn" :
      					 	subscriberData.subscriptionType === "pedagog" ? "Lärarwebb + 7 elever" :
      					 		subscriberData.subscriptionType === "lararwebb" ? "Lärarwebb" : 
                     subscriberData.subscriptionType === "private" ? "Lärarwebb + 1 elev" : null
                }
              </p>
            </div>
          </MyAccountPanelDetails>

          <MyAccountPanelDetails>
            <div className="box">
              <h3>Abonnemang:</h3>
            </div>
            <div className="box">
              <p>{subscriberData.endOfSubscriptionDate}</p>
            </div>
          </MyAccountPanelDetails>

          <MyAccountPanelDetails bgColor={showChangeUserPassword}>
            {!showChangeUserPassword ? (
              <>
                <div className="box">
                  <h3>Lösenord:</h3>
                </div>
                <div className="box password">
                  <p>
                    <span>&bull;</span>
                    <span>&bull;</span>
                    <span>&bull;</span>
                    <span>&bull;</span>
                    <span>&bull;</span>
                    <span>&bull;</span>
                    <span>&bull;</span>
                    <span>&bull;</span>
                  </p>
                </div>
                <div className="box">
                  <AccountButton
                    disabled={changePasswordError}
                    onClick={() => {
                      setShowChangeUserPassword((prev) => !prev);
                    }}
                  >
                    Ändra
                  </AccountButton>
                </div>
              </>
            ) : (
              <>
                <div className="box">
                  <label htmlFor="myAccountPassword">Lösenord: </label>
                </div>
                <div className="box">
                  <TextInput
                    type="password"
                    name="password"
                    id="myAccountPassword"
                    value={newUserInfo.password}
                    onChange={handleNewUserInfo}
                  />
                </div>
                <div className="box">
                  <AccountButton
                    type="submit"
                    disabled={newUserInfo.password.length < 6}
                    onClick={(e) => {
                      changePassword(e);
                      setShowChangeUserPassword(false);
                    }}
                  >
                    Klar
                  </AccountButton>
                </div>
              </>
            )}
          </MyAccountPanelDetails>
          {changePasswordError && (
            <LoginInfo>
              <p>{changePasswordError}</p>
            </LoginInfo>
          )}
        </ContentWrapper>
        <MyAccountInformation>
          <ContentWrapper>
            <h2>Information</h2>
            <TextEditor
              dangerouslySetInnerHTML={{ __html: toHTML(myAccount.body) }}
            />
          </ContentWrapper>
        </MyAccountInformation>
      </MyAccountPanelArticle>
    </>
  );
};
