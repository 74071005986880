import styled from "styled-components";
import { AccountButton } from "../AccountButton";
import { ContentWrapper, TextInput } from "../../common";
import { respondTo } from "../../../styles";

export const MyAccountPanelArticle = styled.article`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 260px;
  column-gap: 65px;
  row-gap: 65px;
  padding-bottom: 90px;

  ${respondTo.xl`
    grid-template-columns: auto 370px;
    grid-template-rows: 100%;
  `}

  ${ContentWrapper} {
    grid-row: 1 / 2;

    ${respondTo.xl`
      grid-row: 1 / 1;
  `}
  }
`;

export const MyAccountInformation = styled.div`
  grid-row: 2 / 3;
  width: 100%;
  background-color: var(--tabBtnColor);
  padding: 28px 32px;
  color: var(--creamWhite);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${respondTo.xl`
      grid-row: 1 / 1;
      width: 370px;
  `}

  h2 {
    color: var(--creamWhite);
    font-size: 24px;
    line-height: 33px;
  }

  ${AccountButton} {
    padding: 10px 26px;
    font-size: 22px;
  }
`;

export const MyAccountPanelDetails = styled.div`
  height: 95px;
  width: 100%;
  background-color: ${(props) => (props.bgColor ? "var(--mercury)" : "white")};
  margin-bottom: 25px;
  display: grid;
  grid-template-columns: 28% 44% 28%;
  grid-template-rows: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }

  .box {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 29px;
    font-size: 24px;
    position: relative;

    button {
      width: 157px;
    }

    label {
      font-size: 22px;
      font-weight: 700;
    }

    ${TextInput} {
      position: absolute;
      font-size: 24px;
      left: 7px;
    }

    &.password {
      p {
        line-height: unset;
      }
      span {
        font-size: 60px;
      }
    }
  }
`;
